<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" class="float-right" top right @click="$router.go(-1)">Zurück</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="10" lg="6">
            <div class="card">
              <div class="card-header card-header-primary">
                <div class="card-title">Wartungs-Block Informationen</div>
              </div>
              <div class="card-body">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <b>Wartungs-Blockname</b>
                        </td>
                        <td>{{ block.name }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Dauer</b>
                        </td>
                        <td>{{ block.duration }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Preis</b>
                        </td>
                        <td>{{ block.price }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Wartungszyklus</b>
                        </td>
                        <td>{{ block.cycle }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nächste Wartung</b>
                        </td>
                        <td>{{ $formatDate(block.nextservice) }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Letzte Wartung</b>
                        </td>
                        <td>{{ $formatDate(block.lastservice) }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Zeitstempel</b>
                        </td>
                        <td>
                          <small>Erstellt am: {{ $formatDate(block.created_at) }}</small>
                          <br />
                          <small>Aktualsiert am: {{ $formatDate(block.updated_at) }}</small>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card-title">Warungshistorie</div>

            <div v-if="history.length > 0">
              <v-expansion-panels multiple focusable>
                <v-expansion-panel v-for="(history,i) in history" :key="i">
                  <v-expansion-panel-header>Durchgeführt am: {{$formatDate(history.finalized)}}, {{history.username}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="card my-4">
                      <div class="card-body">
                        <v-data-table
                          :headers="headers"
                          :items-per-page="-1"
                          :items="history.items"
                          hide-default-footer
                          class="elevation-1"
                        >
                          <template v-slot:item.commentintern="{ item }">
                            <div v-if="item.commentintern">{{JSON.parse(item.commentintern)}}</div>
                          </template>
                          <template v-slot:item.status="{ item }">
                            <div class="text-center">
                              <i
                                style="color:green"
                                v-if="item.status === '1' || item.status === 1"
                                class="material-icons"
                              >check_circle</i>
                              <i v-else style="color:red" class="material-icons">remove_circle</i>
                            </div>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <div class="text-center inline-flex">
                              <v-btn
                                @click="goto('/anlagewartung',['history',item.anlageid,item.wid,item.blockid])"
                                color="primary"
                                small
                                fab
                              >
                                <i class="material-icons">playlist_add_check</i>
                              </v-btn>
                              <v-btn
                                v-if="role === 'admin' || role === 'pl'"
                                @click="printAnlageSum(item.anlageid,item.wid)"
                                color="primary"
                                small
                                fab
                              >
                                <i class="material-icons">print</i>
                              </v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-else>Für diese Anlage wurde bisher keine Wartung durchgeführt.</div>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      block: {},
      history: [],
      headers: [
        {
          text: "Anlagenbezeichnung",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Gebäude",
          align: "left",
          sortable: false,
          value: "gebaeude"
        },
        {
          text: "Standort",
          align: "left",
          sortable: false,
          value: "standort"
        },
        {
          text: "Typ",
          align: "left",
          sortable: false,
          value: "typ"
        },
        {
          text: "Bearbeiter",
          align: "center",
          sortable: false,
          value: "username"
        },
        {
          text: "Kommentar intern",
          align: "left",
          sortable: false,
          value: "commentintern"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },

        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    role() {
      return this.$store.getters.user.role;
    }
  },
  mounted: function() {
    this.getBlock();
    this.getHistoricWartungen();
  },
  methods: {
    getBlock() {
      let formData = new FormData();
      formData.append("id", this.id);
      var self = this;
      this.$http({
        method: "post",
        url: "getBlock.php",
        data: formData
      })
        .then(response => {
          if (response.data && response.data.success && response.data.block) {
            this.block = response.data.block;
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getHistoricWartungen() {
      let formData = new FormData();
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "getHistoricBlockWartungen.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.data) {
            let items = [];
            const data = response.data.data;
            data.forEach(obj => {
              const foundItem = items.find(item => {
                return item.wartungsid === obj.wartungsid;
              });
              if (!foundItem) {
                let anlagen = [
                  {
                    name: obj.name,
                    gebaeude: obj.gebaeude,
                    standort: obj.standort,
                    typ: obj.typ,
                    comment: obj.comment,
                    status: obj.status,
                    wid: obj.wartungsid,
                    blockid: obj.blockid,
                    anlageid: obj.anlageid
                  }
                ];
                let newItem = {
                  wartungsid: obj.wartungsid,
                  comment: obj.comment,
                  username: obj.username,
                  finalized: obj.finalized,
                  items: anlagen
                };
                items.push(newItem);
              } else {
                foundItem.items.push({
                  name: obj.name,
                  gebaeude: obj.gebaeude,
                  standort: obj.standort,
                  typ: obj.typ,
                  comment: obj.comment,
                  status: obj.status
                });
              }
            });
            this.history = items;
          }
        })
        .catch(function(error) {
          error;
        });
    },
    goto(site, params) {
      const path = site + "/" + params.join("/");
      this.$router.push({ path: path });
    },
    printAnlageSum(id, wid) {
      let formData = new FormData();
      formData.append("wid", wid);
      formData.append("anlageid", id);
      var self = this;
      this.$http({
        method: "post",
        url: "printWartungAnlage.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.file) {
            const url =
              this.$http.defaults.serverURL +
              "/print/prints//" +
              response.data.file;
            window.open(url, "_blank");
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function(error) {
          const msg =
            "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
          const color = "error";
          self.$store.dispatch("snackbar", { msg, color });
        });
    }
  }
};
</script>
